<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}">

        <div class="row">
            <validated-textarea class="col-12" label="Description" name="Description" v-model="model.description"
                                :rules="rules.description" :disabled="loading"/>
        </div>

        <div class="row">
            <file-input class="c-file-input col-12" label="Image" v-model="model.image" :disabled="loading"/>
        </div>
        <div class="row">
            <div class="col">
                <image-info :width="6700" :height="4470"/>
            </div>
        </div>

        <div class="row">
            <checkbox-input class="col-6" label="Disable" v-model="model.disabled"
                            :rules="rules.disabled" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddManagement',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.event.management.addEdit,
            rules  : {
                description : {
                    required : true
                },
                image : {
                    required : true
                },
                disabled : {
                    required : false
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Management..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
