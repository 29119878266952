<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'Management'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.managementAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
             <template #image="{rowData}">
                <img :src="prefix + rowData.image" width="150px" alt="img">
             </template>
<!--             <template #create_date="{ rowData }">-->
<!--                <span>{{ formatDate(rowData.create_date) }}</span>-->
<!--             </template>-->
             <template #disabled="{rowData}">
                <checkbox-input v-model="rowData.disabled" @input="disable(rowData)"></checkbox-input>
             </template>
             <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Management" ref="managementAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddManagement @success="formSuccess"></AddManagement>
        </modal>

        <modal title="Edit Management" ref="managementEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditManagement :initial-data="editingItem" @success="formSuccess"></EditManagement>
        </modal>

        <delete-modal ref="managementDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Management <b v-html="deletingItem && deletingItem.management"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import AddManagement from './AddManagement';
import EditManagement from './EditManagement';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name       : 'Management',
    components : { AddManagement, EditManagement },
    computed   : { ...mapGetters(['currentUser']) },
    data () {
        return {
            prefix       : '',
            listUrl      : urls.admin.event.management.list,
            deleteUrl    : urls.admin.event.management.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'description',
                    sortField  : 'description',
                    title      : 'Description',
                    titleClass : ''
                },
                {
                    name       : '__slot:image',
                    sortField  : 'image',
                    title      : 'Image',
                    titleClass : ''
                },
                // {
                //     name       : '__slot:create_date',
                //     sortField  : 'create_date',
                //     title      : 'Create Date',
                //     titleClass : 'datetime-column'
                // },
                {
                    name       : '__slot:disabled',
                    sortField  : 'disabled',
                    title      : 'Disabled',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.managementAddModal.close();
            refs.managementEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.managementEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.managementDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Management Item...!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.managementDeleteModal.close();
            this.$refs.table.refreshTable();
        },
        // formatDate (date) {
        //     const currentDate = new Date(date);
        //
        //     // Calculate the future date by adding 5 hours and 30 minutes
        //     const futureDate = new Date(currentDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
        //
        //     const formattedDate = futureDate.toISOString().slice(0, 19).replace('T', ' ');
        //     return formattedDate;
        // },
        disable (item) {
            const that = this;
            const key = {
                api_key : that.currentUser.api_key
            };
            const data = {
                ...key,
                ...item
            };
            axios.form(urls.admin.event.management.addEdit, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated Management..!', 'Success',
                        {
                            type : 'success'
                        });
                    that.$refs.table.refreshTable();
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disabled = !item.disabled;
                }
            }).catch(function (err) {
                item.disabled = !item.disabled;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
